import { useEffect, useState } from "react"

export const useHasShareCapability = (): boolean => {
  const [hasShareButton, setHasShareButton] = useState(false)

  useEffect(() => {
    setHasShareButton(typeof navigator !== "undefined" && !!navigator.share)
  }, [])

  return hasShareButton
}

/**
 *
 * @param mediaQuery string e.g. value of `theme.breakpoints.mediaQueryHasVisibleLeftPanel`
 */
export const shouldCheckForSharingCapability = (mediaQuery: string) => {
  // check for support first
  if (typeof window == "undefined" || !window.matchMedia) {
    return false
  }

  // greater than medium-sized screen
  const mql = window.matchMedia(mediaQuery)

  // negation = is mobile
  return !mql.matches
}
