import BaseTrackEvent from "@hornet-web-react/core/models/track-event"
import {
  HornetProfileId,
  QuickiesGroupId,
  QuickiesPremiumPaywall,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { DickVerificationModalPage } from "../hooks/use-dick-verification"

class QuickiesAnalyticsEvent extends BaseTrackEvent {
  static navigationTapOnMyProfile(
    profileId: QuickiesProfileId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Navigation_tapOnMyProfile", [
      { key: "profile_id", string_value: profileId },
    ])
  }

  static navigationTapOnShare(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Navigation_tapOnShare", [])
  }

  static myProfileTapOnShare(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("MyProfile_tapOnShare", [])
  }

  static guyTapOnStartChat(
    profileId: QuickiesProfileId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Guy_tapOnStartChat", [
      { key: "profile_id", string_value: profileId },
    ])
  }

  static mapTapOnRegisterAccount(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_tapOnRegisterAccount", [])
  }

  static anonLoginTapOnLogin(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("AnonLogin_tapOnLogin", [])
  }

  static landingTapOnAnonLoginCta(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Landing_tapOnAnonLoginCta", [])
  }

  static landingTapOnLoginCta(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Landing_tapOnLoginCta", [])
  }

  static feedbackTapOnSubmit(rating: number): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Feedback_tapOnSubmit", [
      {
        key: "rating",
        int_value: rating,
      },
    ])
  }

  static anywhereTapOnFeedback(pathname: string): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Anywhere_tapOnFeedback", [
      { key: "router_pathname", string_value: pathname },
    ])
  }

  static profileViewsLimitReached(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("ProfileViewsLimit_reached", [])
  }

  static mapTapOnGuy(profileId: QuickiesProfileId) {
    return new QuickiesAnalyticsEvent("Map_tapOnGuy", [
      { key: "profile_id", string_value: profileId },
    ])
  }

  static mapTapOnGroup(groupId: QuickiesGroupId) {
    return new QuickiesAnalyticsEvent("Map_tapOnGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static mapPhotoUploadReminderShown() {
    return new QuickiesAnalyticsEvent("Map_photoUploadReminderShown", [])
  }

  static photoUploadReminderTapOnUploadPhoto(slot: number) {
    return new QuickiesAnalyticsEvent("PhotoUploadReminder_tapOnUploadPhoto", [
      { key: "slot", int_value: slot },
    ])
  }

  static photoUploadReminderPhotoUploaded() {
    return new QuickiesAnalyticsEvent("PhotoUploadReminder_photoUploaded", [])
  }

  static acceptInviteTapOnUploadPhoto() {
    return new QuickiesAnalyticsEvent("AcceptInvite_tapOnUploadPhoto", [])
  }

  static acceptInvitePhotoUploaded() {
    return new QuickiesAnalyticsEvent("AcceptInvite_photoUploaded", [])
  }

  static acceptInvitePhotoFromHornetUploaded() {
    return new QuickiesAnalyticsEvent(
      "AcceptInvite_photoFromHornetUploaded",
      []
    )
  }

  static acceptInviteTapOnContinueWithHornetAccount() {
    return new QuickiesAnalyticsEvent(
      "AcceptInvite_tapOnContinueWithHornetAccount",
      []
    )
  }

  static ipqCheckFailed(
    screen:
      | "AcceptInvite"
      | "AcceptInviteWithInviteCode"
      | "Onboarding"
      | "AnonLogin"
  ) {
    return new QuickiesAnalyticsEvent("IpqCheck_failed", [
      {
        key: "screen",
        string_value: screen,
      },
    ])
  }

  static acceptInviteWithInviteCodeTapOnUploadPhoto() {
    return new QuickiesAnalyticsEvent(
      "AcceptInviteWithInviteCode_tapOnUploadPhoto",
      []
    )
  }

  static acceptInviteWithInviteCodePhotoUploaded() {
    return new QuickiesAnalyticsEvent(
      "AcceptInviteWithInviteCode_photoUploaded",
      []
    )
  }

  static acceptInviteWithInviteCodePhotoFromHornetUploaded() {
    return new QuickiesAnalyticsEvent(
      "AcceptInviteWithInviteCode_photoFromHornetUploaded",
      []
    )
  }

  static acceptInviteWithInviteCodeTapOnContinueWithHornetAccount() {
    return new QuickiesAnalyticsEvent(
      "AcceptInviteWithInviteCode_tapOnContinueWithHornetAccount",
      []
    )
  }

  static landingTapOnWaitListCta(ctaIndex: number) {
    return new QuickiesAnalyticsEvent("Landing_tapOnWaitListCta", [
      { key: "waitlist_cta_idx", int_value: ctaIndex },
    ])
  }

  static landingTapOnWaitListRegister(ctaIndex: number) {
    return new QuickiesAnalyticsEvent("Landing_tapOnWaitListRegister", [
      { key: "waitlist_cta_idx", int_value: ctaIndex },
    ])
  }

  static profileLimitReached() {
    return new QuickiesAnalyticsEvent("Profile_limitReached", [])
  }

  static feedTapOnSubmit() {
    return new QuickiesAnalyticsEvent("Feed_tapOnSubmit", [])
  }

  static mapTapOnPointOfInterest(pointOfInterestId: string) {
    return new QuickiesAnalyticsEvent("Map_tapOnPointOfInterest", [
      { key: "profile_id", string_value: pointOfInterestId },
    ])
  }

  static profileTapOnDeletePhoto() {
    return new QuickiesAnalyticsEvent("Profile_tapOnDeletePhoto", [])
  }

  static profileTapOnUploadPhoto(slot: number) {
    return new QuickiesAnalyticsEvent("Profile_tapOnUploadPhoto", [
      { key: "slot", int_value: slot },
    ])
  }

  static feedTapOnExpand() {
    return new QuickiesAnalyticsEvent("Feed_tapOnExpand", [])
  }

  static webAccountCancelled() {
    return new QuickiesAnalyticsEvent("Web_accountCancelled", [])
  }

  static guyTapOnReport() {
    return new QuickiesAnalyticsEvent("Guy_tapOnReport")
  }

  static mapTapOnInstallPwa(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_tapOnInstallPwa", [])
  }

  static mapTapOnEnableNotificationsBanner(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_tapOnEnableNotificationsBanner", [])
  }

  static mapIgnoreEnableNotifications(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_ignoreEnableNotifications", [])
  }

  static mapNotificationsDisabledConfirmed(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_notificationsDisabledConfirmed", [])
  }

  static dickVerificationTapOnCancel(
    step: DickVerificationModalPage
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("DickVerification_tapOnCancel", [
      {
        key: "step",
        string_value: step,
      },
    ])
  }

  static mapTapOnEnableNotificationsResult(
    result: string
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Map_tapOnEnableNotificationsResult", [
      {
        key: "result",
        string_value: result,
      },
    ])
  }

  static guyTapOnPhoto(profileId: HornetProfileId) {
    return new QuickiesAnalyticsEvent("Guy_tapOnPhoto", [
      { key: "profileId", string_value: profileId },
    ])
  }

  static premiumOpened(paywall: QuickiesPremiumPaywall) {
    return new QuickiesAnalyticsEvent("Premium_opened", [
      { key: "paywall", string_value: paywall },
    ])
  }

  static accountTapOnCancelPremium() {
    return new QuickiesAnalyticsEvent("Account_tapOnCancelPremium")
  }

  static checkoutTapOnBack() {
    return new QuickiesAnalyticsEvent("Checkout_tapOnBack")
  }

  static navigationTapOnLogout(pathname: string) {
    return new QuickiesAnalyticsEvent("Navigation_tapOnLogout", [
      { key: "router_pathname", string_value: pathname },
    ])
  }

  static navigationTapOnMap() {
    return new QuickiesAnalyticsEvent("Navigation_tapOnMap", [])
  }

  static navigationTapOnChats() {
    return new QuickiesAnalyticsEvent("Navigation_tapOnChats", [])
  }

  static userInviteTapOnShare(invitesCount: number): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("UserInvite_tapOnShare", [
      {
        key: "count",
        int_value: invitesCount,
      },
    ])
  }

  static navigationTapOnGroups() {
    return new QuickiesAnalyticsEvent("Navigation_tapOnGroups", [])
  }

  static hostGroupTapOnPreview() {
    return new QuickiesAnalyticsEvent("HostGroup_tapOnPreview", [])
  }

  static hostGroupTapOnPublish() {
    return new QuickiesAnalyticsEvent("HostGroup_tapOnPublish", [])
  }

  static hostGroupPhotoUploaded(groupId: QuickiesGroupId) {
    return new QuickiesAnalyticsEvent("HostGroup_photoUploaded", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupInviteModalTapOnDone(
    profilesCount: number,
    groupId: QuickiesGroupId
  ) {
    return new QuickiesAnalyticsEvent("GroupInviteModal_tapOnDone", [
      {
        key: "count",
        int_value: profilesCount,
      },
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnShowOnMap(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnShowOnMap", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnAskToJoin(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnAskToJoin", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnCancelRequest(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnCancelRequest", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnAcceptInvite(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnAcceptInvite", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnDeclineInvite(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnDeclineInvite", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnLeaveGroup(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnLeaveGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnDeleteGroup(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnDeleteGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupPreviewModalTapOnEditGroup(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupPreviewModal_tapOnEditGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static groupsDashboardTapOnHostGroup(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupsDashboard_tapOnHostGroup", [])
  }

  static introducingGroupsTapOnHostGroup(): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("IntroducingGroups_tapOnHostGroup", [])
  }

  static groupsDashboardTapOnGroup(
    groupId: QuickiesGroupId
  ): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("GroupsDashboard_tapOnGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }

  static profileTapOnGroup(groupId: QuickiesGroupId): QuickiesAnalyticsEvent {
    return new QuickiesAnalyticsEvent("Profile_tapOnGroup", [
      { key: "group_id", string_value: groupId },
    ])
  }
}

export default QuickiesAnalyticsEvent
