import useImmutableSWR, { mutate } from "swr"
import QuickiesMemberModel, {
  createQuickiesMemberModel,
  QuickiesMemberApiPayload,
} from "../models/quickies-member.model"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useMemo } from "react"

import { QuickiesProfileId } from "@hornet-web-react/core/types"
import useTranslation from "next-translate/useTranslation"

type UseQuickiesProfile = {
  data: QuickiesMemberModel | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useQuickiesMember(
  quickiesProfileId: QuickiesProfileId | undefined
): UseQuickiesProfile {
  const { data, error } = useImmutableSWR<QuickiesMemberApiPayload>(
    quickiesProfileId
      ? ApiService.getEndpoint(ApiServiceEndpoint.QuickiesMemberGet, [
          quickiesProfileId,
        ])
      : null,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    data: useMemo(() => createQuickiesMemberModel(data), [data]),
    isLoading: !error && !data,
    error,
  }
}

export function reloadQuickiesProfile(quickiesProfileId: QuickiesProfileId) {
  return mutate<QuickiesMemberApiPayload>(
    ApiService.getEndpoint(ApiServiceEndpoint.QuickiesMemberGet, [
      quickiesProfileId,
    ])
  )
}

export function updateQuickiesProfileLocally(data: {
  serialize: () => unknown
  profileId: QuickiesProfileId
}) {
  const serializedData = data.serialize() as QuickiesMemberApiPayload

  return mutate<QuickiesMemberApiPayload>(
    ApiService.getEndpoint(ApiServiceEndpoint.QuickiesMemberGet, [
      data.profileId,
    ]),
    serializedData,
    {
      revalidate: false,
      optimisticData: serializedData,
      rollbackOnError: false,
    }
  )
}

export async function setQuickiesMemberIsFavouriteLocally(
  data: QuickiesMemberModel,
  isFavourite: boolean
) {
  return updateQuickiesProfileLocally(data.setIsFavourite(isFavourite))
}

export function useQuickiesMemberDefaultDisplayName() {
  const { t } = useTranslation()

  return t(
    "quickies:hooks.use_quickies_member_default_display_name.default_display_name"
  )
}
