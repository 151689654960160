import { ApiRequestError, useApi } from "./use-api"
import { useCallback } from "react"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { Either, isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { z } from "zod"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"

const HornetIpQualityCheckApiPayloadEncoded = z.object({
  r: z.string(),
})
type HornetIpQualityCheckApiPayloadEncoded = z.infer<
  typeof HornetIpQualityCheckApiPayloadEncoded
>

const HornetIpQualityCheckApiPayloadDecoded = z.object({
  vpn_or_tor: z.boolean().nullable(),
})

type HornetIpQualityCheckApiResult = Either<
  ApiRequestError,
  HornetIpQualityCheckApiPayloadEncoded
>

type UseHornetIpQualityCheckProps = {
  hasPremium: boolean
  logoutAction: (targetLocation: string) => Promise<void>
  getRouteToTrafficFailure: (failedCheck: string) => string
}

export const useHornetIpQualityCheck = (
  props: UseHornetIpQualityCheckProps
) => {
  const { makeApiRequest, getEndpoint } = useApi()
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { country, locale } = useSessionDevice()

  const { logoutAction, getRouteToTrafficFailure, hasPremium } = props

  const requestHornetIpQualityCheck = useCallback(
    async (): Promise<HornetIpQualityCheckApiResult> =>
      makeApiRequest<HornetIpQualityCheckApiPayloadEncoded>(
        getEndpoint(ApiServiceEndpoint.HornetIpQualityCheckGet)
      ),
    [getEndpoint, makeApiRequest]
  )

  const handleHornetIpQualityCheckFailure = useCallback(
    async (reason: string, screen: string) => {
      // only log it, when user is not Premium and they will get affected eventually
      // when we turn on the hard stop for the HIPQ check failure
      if (!hasPremium) {
        void eventTrackerService.report(
          TrackEvent.hornetIpQualityCheckFailed(reason, screen)
        )

        loggerService.logExceptionWithSentry(
          new Error(`Hornet IP Quality check failed: ` + reason),
          loggerService.createLoggingContext({
            screen,
            country,
            locale,
          })
        )
      }

      // prevent bots only on the "Onboarding" screen (free market)
      // otherwise this handle is disabled for non-free markets
      if (screen === "Onboarding") {
        // give 500ms to report the event before logging out
        await new Promise((r) => setTimeout(r, 500))

        void logoutAction(getRouteToTrafficFailure(reason))
      }
    },
    [
      hasPremium,
      eventTrackerService,
      loggerService,
      country,
      locale,
      logoutAction,
      getRouteToTrafficFailure,
    ]
  )

  const handleHornetIpQualityCheckResult = useCallback(
    async (
      apiResult: HornetIpQualityCheckApiResult,
      screen = "undefined"
    ): Promise<boolean> => {
      try {
        if (isRight(apiResult)) {
          const result = unwrapEither(apiResult)
          const decodedResult = HornetIpQualityCheckApiPayloadDecoded.parse(
            JSON.parse(atob(result.r))
          )

          const { vpn_or_tor } = decodedResult

          if (vpn_or_tor) {
            void handleHornetIpQualityCheckFailure("vpn_or_tor", screen)
            return false
          }
          return true
        }

        void handleHornetIpQualityCheckFailure("api_error", screen)
        return false
      } catch (error) {
        if (error instanceof Error) {
          loggerService.logExceptionWithSentry(
            error,
            loggerService.createLoggingContext({
              screen,
            })
          )
        }

        void handleHornetIpQualityCheckFailure("unknown_error", screen)
        return false
      }
    },
    [handleHornetIpQualityCheckFailure, loggerService]
  )

  return {
    requestHornetIpQualityCheck,
    handleHornetIpQualityCheckResult,

    executeCheckAndHandleFailure: useCallback(
      async (screen?: string): Promise<boolean> => {
        // TODO: eventually turn this off completely if user has Premium
        // only keeping it working for now because I want to log how many
        // of the failed Premiums we have
        const apiResult = await requestHornetIpQualityCheck()
        return await handleHornetIpQualityCheckResult(apiResult, screen)
      },
      [handleHornetIpQualityCheckResult, requestHornetIpQualityCheck]
    ),
  }
}
