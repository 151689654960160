import { z } from "zod"
import MemberPhotoModel, {
  createMemberPhotoModel,
  MemberPhotoApiPayload,
} from "@hornet-web-react/core/models/member-photo.model"
import { identity, prop, sortBy } from "ramda"
import { MemberOnlineStatus } from "@hornet-web-react/core/models/member.model"
import { QuickiesLookupDataItem } from "./quickies-lookup-data.model"
import { differenceInDays } from "date-fns"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { printZodValidationError } from "@hornet-web-react/core/utils/print-zod-validation-error"
import { DateFormFn } from "@hornet-web-react/core/hooks/use-localized-date-format"
import {
  createQuickiesGroupModel,
  QuickiesGroupApiPayload,
  QuickiesGroupModel,
} from "./quickies-group.model"

export const HornetXProfile = z.object({
  favourite: z.boolean(),
  id: HornetProfileId,
})
export type HornetXProfile = z.infer<typeof HornetXProfile>

export const QuickiesMemberBaseApiPayload = z.object({
  id: QuickiesProfileId,
  share_id: z.string().optional(),
  hornet_x_profile_id: HornetProfileId,
  hornet_x_profile: HornetXProfile,
  anonymous: z.boolean(),
  lat: z.number().nullable(),
  lng: z.number().nullable(),
  quickies_photos: z.array(MemberPhotoApiPayload),

  dick_verified: z.boolean().optional(),

  location: z.string().nullable(),
  headline: z.string().nullable(),

  age: z.number().nullable(),
  height: z.number().nullable(),
  weight: z.number().nullable(),
  body_type: QuickiesLookupDataItem.nullable(),
  endowment: z.number().nullable(),
  distance: z.number().nullable(),
  sexuality_attitude: QuickiesLookupDataItem.nullable(),
  sexuality_expression: QuickiesLookupDataItem.nullable(),
  sexuality_spectrum: QuickiesLookupDataItem.nullable(),
  scene_locations: z.array(QuickiesLookupDataItem).nullable(),
  scene_into_publics: z.array(QuickiesLookupDataItem).nullable(),
  scene_looking_fors: z.array(QuickiesLookupDataItem).nullable(),
  scene_fetishes: z.array(QuickiesLookupDataItem).nullable(),
  scene_kinks: z.array(QuickiesLookupDataItem).nullable(),
  scene_intos: z.array(QuickiesLookupDataItem).nullable(),
  scene_interactions: z.array(QuickiesLookupDataItem).nullable(),
  health_protection: QuickiesLookupDataItem.nullable(),
  health_hiv_status: QuickiesLookupDataItem.nullable(),
  health_hiv_tested_at: z.coerce.date().nullable(),
  health_std_tested_at: z.coerce.date().nullable(),
  health_safeguards: z.array(QuickiesLookupDataItem).nullable(),
})

export const QuickiesMemberApiPayload = z.object({
  quickies_member: QuickiesMemberBaseApiPayload.extend({
    online: z.boolean(),
    last_online: z.coerce.date().nullable(),
    hosting_hangouts: z.array(QuickiesGroupApiPayload).optional(),
  }),
})
export type QuickiesMemberApiPayload = z.infer<typeof QuickiesMemberApiPayload>

export const createQuickiesMemberModel = (
  payload?: QuickiesMemberApiPayload
) => {
  const model = QuickiesMemberApiPayload.safeParse(payload)
  if (model.success) {
    return new QuickiesMemberModel(model.data)
  }

  payload && printZodValidationError(model.error)
}

export default class QuickiesMemberModel {
  protected readonly member: QuickiesMemberApiPayload["quickies_member"]
  readonly photos: MemberPhotoModel[]
  readonly hostingGroups: QuickiesGroupModel[]

  constructor(payload: QuickiesMemberApiPayload) {
    this.member = payload.quickies_member

    this.photos = sortBy(
      (photo) => photo.slot,
      this.member.quickies_photos.map(createMemberPhotoModel)
    )

    this.hostingGroups = (payload.quickies_member.hosting_hangouts || [])
      .map(createQuickiesGroupModel)
      .filter(identity) as QuickiesGroupModel[]
  }

  get isAnonymous() {
    return this.member.anonymous
  }

  get isFavourite() {
    return this.member.hornet_x_profile.favourite === true
  }

  get profilePhoto(): MemberPhotoModel | undefined {
    const publicPhotos = this.photos.filter((photo) => photo.isPublic)

    if (!publicPhotos.length) {
      return undefined
    }

    // try find non-default one
    const nonDefaultPhoto = publicPhotos.find((photo) => !photo.isDefault)
    if (nonDefaultPhoto) {
      return nonDefaultPhoto
    }

    return publicPhotos[0]
  }

  get hasCustomProfilePhoto() {
    return (
      typeof this.profilePhoto !== "undefined" && !this.profilePhoto.isDefault
    )
  }

  get customPhotos() {
    // filter out default photos
    return this.photos.filter((photo) => !photo.isDefault)
  }

  get hasProfilePhoto() {
    return typeof this.profilePhoto !== "undefined"
  }

  get profileId(): QuickiesProfileId {
    return this.member.id
  }

  get hornetProfileId(): HornetProfileId {
    return this.member.hornet_x_profile_id
  }

  get onlineStatus(): MemberOnlineStatus {
    return {
      isOnline: this.member.online,
      lastOnline: this.member.last_online,
    }
  }

  get isLastOnlineWithinWeek() {
    if (!this.member.last_online) {
      return false
    }

    const diffInDays = differenceInDays(new Date(), this.member.last_online)

    return diffInDays <= 7
  }

  get displayName(): string | null {
    const displayName: string[] = []
    if (this.age) {
      displayName.push(`${this.age}`)
    }

    if (this.height) {
      displayName.push(`${this.height}cm`)
    }

    if (this.weight) {
      displayName.push(`${this.weight}kg`)
    }

    if (this.bodyType) {
      displayName.push(`${this.bodyType.title}`)
    }

    if (this.endowment) {
      displayName.push(`${this.endowment}cm`)
    }

    if (displayName.length) {
      return displayName.join(", ")
    }

    // default stuff: null for i18n
    return null
  }

  get age() {
    return this.member.age
  }

  get headline() {
    return this.member.headline
  }

  get height() {
    return this.member.height
  }

  get weight() {
    // API returns it in grams
    return this.member.weight !== null ? this.member.weight / 1000 : null
  }

  get endowment() {
    return this.member.endowment
  }

  get lat() {
    return this.member.lat
  }

  get lng() {
    return this.member.lng
  }

  get bodyType() {
    return this.member.body_type
  }

  get displayDistance() {
    if (!this.member.distance) {
      return ""
    }

    if (this.member.distance < 1) {
      return Math.floor(this.member.distance * 1000) + " m"
    }

    const fractionDigits = this.member.distance > 5 ? 0 : 1

    // km
    return this.member.distance.toFixed(fractionDigits) + " km"
  }

  get sexualityAttitude() {
    return this.member.sexuality_attitude
  }

  get sexualityExpression() {
    return this.member.sexuality_expression
  }

  get sexualitySpectrum() {
    return this.member.sexuality_spectrum
  }

  // to comply with Hornet-way of things
  get featuredPhotos(): MemberPhotoModel[] {
    return this.photos
  }

  // to comply with Hornet-way of things
  get privatePhotos(): MemberPhotoModel[] {
    return []
  }

  get titleSexuality() {
    return [
      this.sexualityAttitude?.title,
      this.sexualityExpression?.title,
      this.sexualitySpectrum?.title,
    ]
      .filter(Boolean)
      .join(", ")
  }

  get sceneLocations() {
    return this.member.scene_locations ?? []
  }

  get titleSceneLocations() {
    return this.sceneLocations.map(prop("title")).join(", ")
  }

  get sceneIntoPublics() {
    return this.member.scene_into_publics ?? []
  }

  get titleSceneIntoPublics() {
    return this.sceneIntoPublics.map(prop("title")).join(", ")
  }

  get sceneLookingFors() {
    return this.member.scene_looking_fors ?? []
  }

  get titleSceneLookingFors() {
    return this.sceneLookingFors.map(prop("title")).join(", ")
  }

  get sceneFetishes() {
    return this.member.scene_fetishes ?? []
  }

  get titleSceneFetishes() {
    return this.sceneFetishes.map(prop("title")).join(", ")
  }

  get sceneKinks() {
    return this.member.scene_kinks ?? []
  }

  get titleSceneKinks() {
    return this.sceneKinks.map(prop("title")).join(", ")
  }

  get sceneIntos() {
    return this.member.scene_intos ?? []
  }

  get titleSceneIntos() {
    return this.sceneIntos.map(prop("title")).join(", ")
  }

  get sceneInteractions() {
    return this.member.scene_interactions ?? []
  }

  get titleSceneInteractions() {
    return this.sceneInteractions.map(prop("title")).join(", ")
  }

  get healthProtection() {
    return this.member.health_protection
  }

  get healthHivStatus() {
    return this.member.health_hiv_status
  }

  get healthSafeguards() {
    return this.member.health_safeguards ?? []
  }

  get healthHivTestedAt() {
    return this.member.health_hiv_tested_at
  }

  get healthStdTestedAt() {
    return this.member.health_std_tested_at
  }

  get hasHealthProtectionTitle() {
    return !!this.healthProtection?.title || this.healthSafeguards.length > 0
  }

  getHealthProtectionTitle(dateFormat: DateFormFn) {
    const healthStdTestedAtFormatted = this.healthStdTestedAt
      ? dateFormat(this.healthStdTestedAt, "do MMM yyyy")
      : ""

    return [
      this.healthProtection?.title,
      ...this.healthSafeguards.map(prop("title")),
      healthStdTestedAtFormatted ? healthStdTestedAtFormatted : null,
    ]
      .filter(Boolean)
      .join(", ")
  }

  get hasHealthHivStatusTitle() {
    return !!this.healthHivStatus?.title
  }

  getHealthHivStatusTitle(dateFormat: DateFormFn) {
    const healthHivTestedAt = this.healthHivTestedAt
      ? dateFormat(this.healthHivTestedAt, "do MMM yyyy")
      : ""

    return [
      this.healthHivStatus?.title,
      healthHivTestedAt ? `(${healthHivTestedAt})` : null,
    ]
      .filter(Boolean)
      .join(" ")
  }

  get shareId() {
    return this.member.share_id
  }

  get hasVerifiedDick() {
    return this.member.dick_verified === true
  }

  get firstVerifiedDickUrl(): string | null {
    return this.verifiedDickPhotos.length > 0
      ? this.verifiedDickPhotos[0].squareUrl
      : null
  }

  private get verifiedDickPhotos() {
    return this.photos.filter((p) => p.isVerifiedDick)
  }

  serialize(): QuickiesMemberApiPayload {
    return {
      quickies_member: {
        ...this.member,
        quickies_photos: this.photos.map((p) => p.serialize()),
      },
    }
  }

  // action is used in `use-profile-photo-actions.ts`
  addPhoto(photo: MemberPhotoModel) {
    return new QuickiesMemberModel({
      quickies_member: {
        ...this.member,
        quickies_photos: [
          ...this.photos.map((p) => p.serialize()),
          photo.serialize(),
        ],
      },
    })
  }

  // action is used in `use-profile-photo-actions.ts`
  deletePhoto(photo: MemberPhotoModel) {
    return new QuickiesMemberModel({
      quickies_member: {
        ...this.member,
        quickies_photos: this.photos
          .filter((p) => p.id !== photo.id)
          .map((p) => p.serialize()),
      },
    })
  }

  // action is used in `use-profile-photo-actions.ts`
  setAsProfilePhoto(photo: MemberPhotoModel) {
    const newPhotos = [
      // set the photo as the first one
      photo.setAsProfilePhoto().serialize(),
      // then re-order the rest
      ...this.featuredPhotos
        .filter((p) => p.id !== photo.id)
        .reduce((acc, p, currentIndex) => {
          acc.push(p.changeSlot(currentIndex + 1).serialize())

          return acc
        }, [] as MemberPhotoApiPayload[]),
      // and finally add also private ones
      ...this.privatePhotos.map((p) => p.serialize()),
    ]

    return new QuickiesMemberModel({
      quickies_member: {
        ...this.member,
        quickies_photos: newPhotos,
      },
    })
  }

  // action is used in `use-profile-photo-actions.ts`
  togglePhotoStatus(photo: MemberPhotoModel, newSlot: number) {
    return new QuickiesMemberModel({
      quickies_member: {
        ...this.member,
        quickies_photos: this.photos.map((p) => {
          if (p.id === photo.id) {
            return p.toggleStatus(newSlot).serialize()
          }

          return p.serialize()
        }),
      },
    })
  }

  setIsFavourite(isFavourite: boolean) {
    return new QuickiesMemberModel({
      quickies_member: {
        ...this.member,
        hornet_x_profile: {
          ...this.member.hornet_x_profile,
          favourite: isFavourite,
        },
      },
    })
  }
}
