import { QuickiesSessionFeatureFlag } from "../models/types"
import { useQuickiesSession } from "./use-quickies-session"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { Country } from "@hornet-web-react/core/types/session"

type UseQuickiesFeatureFlagsResult = {
  isDickVerificationEnabled: boolean
  hasProfileDeactivationEnabled: boolean
  hasEnhancedLocationPrivacyEnabled: boolean
  hasGroupsEnabled: boolean
}

export const useQuickiesFeatureFlags = (): UseQuickiesFeatureFlagsResult => {
  const { data: quickiesSession } = useQuickiesSession()
  const { country } = useSessionDevice()

  const isDickVerificationEnabled = !!quickiesSession?.isFeatureEnabled(
    QuickiesSessionFeatureFlag.enum.dick_verification
  )

  const hasProfileDeactivationEnabled =
    !!quickiesSession &&
    !quickiesSession.isAnonymous &&
    country !== Country.unwrap().enum.ua &&
    country !== Country.unwrap().enum.id &&
    country !== Country.unwrap().enum.tw

  return {
    isDickVerificationEnabled,
    hasProfileDeactivationEnabled,
    hasEnhancedLocationPrivacyEnabled: country === Country.unwrap().enum.ua,
    hasGroupsEnabled: true,
  }
}
